<template>
  <div>
    <b-modal
      id="modal-payment-categories"
      size="xl"
      title="Tambah Produk Layanan"
      hide-footer
    >
      <div class="row">
        <div class="col-md-8">
          <Table
            :selectedItem="dataSelected"
            :dataId="dataId"
            @chooseItem="chooseItem"
          />
        </div>
        <div class="col-md-4" style="margin-top: 35px">
          <b-table
            striped
            hover
            :items="dataSelected"
            :fields="fields"
            
          >
            <template #table-colgroup="scope">
              <col v-for="field in scope.fields" :key="field.key" />
            </template>
            <template #cell(quantity)="data">
              <div class="row">
                <div class="col-md-12">
                  <b-button
                    size="sm"
                    class="btn-info"
                    style="padding: 5px !important"
                    @click="minusQty(data.item.id)"
                  >
                    <i class="fas fa-minus fa-md px-0"></i>
                  </b-button>
                  <b-button size="sm" class="btn btn-white">{{
                    parseInt(data.item.quantity).toLocaleString("id-ID")
                  }}</b-button>
                  <b-button
                    size="sm"
                    class="btn-info"
                    style="padding: 5px !important"
                    @click="addQty(data.item.id)"
                  >
                    <i class="fas fa-plus fa-md px-0"></i>
                  </b-button>
                </div>
              </div>
            </template>
            <template #cell(name)="data"
            >
               
              <div class="row">
                <div class="col-12 py-1 px-2 text-truncate">
                  {{ data.item.name }}
                </div>
                <div class="col-12 py-1 px-2">
                  <span>
                  <b>({{
                    parseInt(data.item.price).toLocaleString("id-ID")
                  }})</b>
                  </span>
                </div>
              </div>    
              
            </template>

            <template #cell(actions)="data">
              <b-button
                size="sm"
                class="btn-danger mr-1"
                style="padding: 5px !important"
                @click="deleteData(data.item.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row justify-content-end">
        <button class="btn btn-primary mx-1" @click="saveService">
          Simpan
        </button>
        <button class="btn btn-secondary mx-1" @click="cancle">Batal</button>
      </div>

      <!-- <Table
        purpose="modal"
        modalPurpose="modal"
        :isHospitalTransaction="isHospitalTransaction"
        :selectedItem="selectedItem"
        :checkbox="checkbox"
        :isSortcut ="isSortcut"
        :isAbjad ="isAbjad"
        @chooseMedicine="chosenMedicine"
        @packageMedicine="packageMedicine"
        :secondPurpose="secondPurpose"
      /> -->
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/masters/payment-categories/TableModal.vue";

export default {
  props: {
    selectedItem: Array,
    // checkbox: Boolean,
    // isHospitalTransaction: Boolean,
    // isSortcut: Boolean,
    // isAbjad: Boolean,
    // secondPurpose:{
    //   type: String,
    //   default: 'listing'
    // }
  },

  data() {
    return {
      dataSelected: [],

      // fields
      fields: [
        {
          key: "actions",
          label: "",
          thClass: "nowrap",
          tdClass: "p-1",
        },
        {
          key: "name",
          label: "Produk",
          sortable: true,
          tdClass: "setWidth",
          
        },
        {
          key: "quantity",
          label: "Jumlah",
          sortable: true,
          thClass: "nowrap",
          tdClass: "p-1 nowrap",
        },
      ],

      // other
      dataId: "",
      dataIndex: "",
    };
  },

  components: {
    Table,
  },

  methods: {
    async saveService() {
      // send data and id parent
      console.log(this.dataSelected,"==================== data ==============");
      this.$root.$emit(
        "sendSaveService",
        this.dataSelected,
        this.dataId,
        this.dataIndex
      );
      this.saveDataMedicine(this.dataSelected, this.dataId);
    },

    addQty(val){
      let index = this.dataSelected.findIndex(
        (x) => x.id == val
      );
      
      this.dataSelected[index].quantity += 1 
    },

    minusQty(val){
      let index = this.dataSelected.findIndex(
        (x) => x.id == val
      );

      if(this.dataSelected[index].quantity < 1 && this.dataSelected[index].quantity == 0){
        Swal.fire(
          "Perhatian",
          "Jumlah tidak boleh kurang dari 1",
          "warning"
        );
        this.dataSelected[index].quantity = 1
      }else{
        this.dataSelected[index].quantity -= 1 
      }
    },

    async saveDataMedicine(data, id) {
      let result = await module.submit(
        data,
        "payment-categories-bundle/add/" + id
      );
      this.$bvModal.hide("modal-payment-categories");
      this.dataSelected = [];
    },

    cancle() {
      this.$bvModal.hide("modal-payment-categories");
      this.dataSelected = [];
    },

    chooseItem(value) {
      if (this.dataSelected.findIndex((x) => x.id == value.id) < 0) {
        value.quantity = 1;
        this.dataSelected.push(value);
      } else {
        let index = this.dataSelected.findIndex((x) => x.id == value.id);
        this.dataSelected.splice(index, 1);
      }

      this.$root.$emit("updateService", this.dataSelected);
    },

    deleteData(id) {
      let a;
      for (a = 0; a < this.dataSelected.length; a++) {
        if (this.dataSelected[a].id == id) {
          this.dataSelected.splice(a, 1);
          console.log("hasil", this.dataSelected);
        }
      }

      this.$root.$emit("updateService", this.dataSelected);
    },
  },
  mounted() {
    this.dataSelected = this.selectedItem;
    this.$root.$on("sendData", (id, index) => {
      this.dataId = id;
      this.dataIndex = index;
    });
  },
};
</script>

<style>
  .setWidth {
    max-width: 180px;
  }
</style>