<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Layanan</b></div>
      <b-button
        squared
        variant="success"
        class="mx-1"
        @click="$router.push('/payment-categories/add')"
        v-if="btn"
        >Tambah</b-button
      >
      <!-- <b-button
        squared
        variant="outline-primary"
        class="mx-1"
        @click="$router.push('/payment-categories/import')"
      >
        <i class="fas fa-file-excel pr-1"></i>
        Unggah Excel</b-button
      > -->
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Table from "@/component/masters/payment-categories/Table.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
    Table,
  },

  data() {
    return {
      btn: false,
      btnAddDoctorCommission: false,
    };
  },

  methods: {
    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6009") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Layanan" },
      { title: "Layanan" },
    ]);
    this.setActiveButton();
  },
};
</script>
